<template>
  <div class="onboarding-content-wrapper">
    <div class="onboarding-city">
      <p class="onboarding-content-title">What's your home airport?</p>
      <LocationInput
        id="city-input"
        v-model="value"
        style="margin-top: 2rem"
        :label="$t('form.location')"
        :placeholder="$t('form.location')"
        :location-type="LOCATION.TYPE.AIRPORT"
        use-nearest-location
      />
    </div>
    <XButton
      type="large"
      style="margin-top: auto"
      :disabled="!value"
      @click="save()"
    >
      Next
    </XButton>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LocationInput from '../../components-rf/forms/LocationInput.vue'
import XButton from '../../components/common/global/XButton.vue'
import { LOCATION } from '../../constants/LOCATION'

export default {
  name: 'OnboardingCity',
  components: { LocationInput, XButton },
  created() {
    const location = this.userLocation
    if (location) this.value = location.id
  },
  data() {
    return {
      LOCATION,
      value: '',
    }
  },
  methods: {
    save() {
      const location = this.findLocationById(this.value)
      if (!location) return
      this.$store.commit('OnboardingModule/setAirportId', location.identifier)
      this.$router.push('/onboarding/info')
    },
  },
  computed: {
    ...mapGetters('UserModule', ['userLocation']),
    ...mapGetters('LocationModule', ['findLocationById']),
  },
}
</script>

<style lang="scss" scoped>
.onboarding-city {
  align-self: center;
}
</style>
